// // export class ApiUrls {
// //     static apiEnvironment = "http://localhost:3000/"; // localcls
// //     // static apiEnvironment = "https://intranet.gj-consultinggroup.com/"; // prod
// // }
// // export class ApiUrls {
// //     static apiEnvironment = process.env.NODE_ENV === 'production' ? "https://intranet.gj-consultinggroup.com/" : "http://localhost:3000/";
// // }
// export class ApiUrls {
//     static apiEnvironment = process.env.NODE_ENV === 'production' ? "https://uat-intranet.gj-consultinggroup.com/" : "http://localhost:3000/";
// }

// // export class ApiUrls {
// //     static apiEnvironment =
// //         process.env.NODE_ENV === 'production'
// //             ? "https://intranet.gj-consultinggroup.com/"
// //             : process.env.NODE_ENV === 'uat'
// //                 ? 'https://uat-intranet.gj-consultinggroup.com/'
// //                 : "http://localhost:3000/";

// //     static logEnvironment() {
// //         console.log(process.env.NODE_ENV);
// //     }
// // }

export class ApiUrls {

    static apiEnvironment = process.env.NODE_ENV === 'production' ? "https://intranet.gj-consultinggroup.com/" : "http://localhost:3000/";
    // static apiEnvironment = process.env.NODE_ENV === 'production' ? "https://intranet.gj-consultinggroup.com/" : "http://localhost:3000/";
}
