/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Components routing
const Dashboard = Loadable(lazy(() => import('views/screens/dashboard/MainDashboard/index')));
const Timesheet = Loadable(lazy(() => import('views/screens/time_sheet_dashboard/index')));
const ProfileSetting = Loadable(lazy(() => import('views/screens/profile/profilesetting')))
const ConsultantUserReport = Loadable(lazy(() => import('views/screens/userReports/consultantUserreport/index')))
const TraningReport = Loadable(lazy(() => import('views/screens/report/TrainingReport/index')))
// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UserListCard = Loadable(lazy(() => import('views/screens/dashboard/adminDashboard/submittedlistDashboard/index')));

const AssetTrackinguser = Loadable(lazy(() => import('views/modules/UserModules/assetTracking/index')))
const TalentAcquision = Loadable(lazy(() => import('views/modules/UserModules/TalentAcquision/index')))
const TalentProfileView = Loadable(lazy(() => import('views/modules/AdminModules/TalentAcquision/TalentprofileView')))
const TrainingUserDashboard = Loadable(lazy(() => import('views/modules/UserModules/Training/index')))
const TrainingUserDocument = Loadable(lazy(() => import('views/modules/UserModules/Training/TrainingUserDocument')))
const UserWeeksheet = Loadable(lazy(() => import('views/screens/dashboard/adminDashboard/userTimesheet/index')));
// ==============================|| MAIN ROUTING ||============================== //
const OverviewevaluApprover = Loadable(
    lazy(() =>
        import(
            'views/modules/AdminModules/PerfomanceEvaluation/overviewEvaluationApprover/index'
        )
    )
);
const PerfomanceEvaluationApprover = Loadable(
    lazy(() =>
        import(
            'views/modules/AdminModules/PerfomanceEvaluation/evaluationResultApprover/index'
        )
    )
);
// Supplier routings
const SupplierInvoice = Loadable(lazy(() => import('views/modules/SupplierModules/Invoice/index')))
const SupplierInvoiceAdmin = Loadable(lazy(() => import('views/modules/AdminModules/Invoice/index')))

const PerfomanceEvaluationInstrunction = Loadable(lazy(() => import('views/modules/UserModules/PerfomanceEvaluation/Instrunction/index')))
const PerfomanceEvaluationInstrunctionApprover = Loadable(lazy(() => import('views/modules/AdminModules/PerfomanceEvaluation/Instruction/index')))
const PeerFeedbackApprover = Loadable(lazy(() => import('views/modules/AdminModules/PerfomanceEvaluation/PeerFeedbackApprover/index')))
const PeerFeedbackUser = Loadable(lazy(() => import('views/modules/UserModules/PerfomanceEvaluation/PeerFeedbackUser/index')))
const PerfomanceEvaluationForm = Loadable(lazy(() => import('views/modules/UserModules/PerfomanceEvaluation/Exams/index')))
const PerfomanceEvaluationFormApprover = Loadable(lazy(() => import('views/modules/UserModules/PerfomanceEvaluation/Exams/index')))
const PerfomanceOverview = Loadable(lazy(() => import('views/modules/UserModules/PerfomanceEvaluation/Overview/index')))
const UserRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <Dashboard />
                }
            ]
        },

        {
            path: '/evaluationoverview',
            element: <PerfomanceOverview />
        },
        {
            path: 'evaluationoverview',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceOverview />
                }
            ]
        },

        {
            path: '/evaluationoverview/perfomanceevaluationinstrunction',
            element: <PerfomanceEvaluationInstrunction />
        },
        {
            path: 'evaluationoverview/perfomanceevaluationinstrunction',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceEvaluationInstrunction />
                }
            ]
        },
        {
            path: '/overviewEvaluationApprover/perfomanceevaluationinstrunction',
            element: <PerfomanceEvaluationInstrunctionApprover />
        },
        {
            path: 'overviewEvaluatiionApprover/perfomanceevaluationinstrunction',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceEvaluationInstrunctionApprover />
                }
            ]
        },
        {
            path: '/overviewEvaluationApprover/peerfeedback',
            element: <PeerFeedbackApprover />
        },
        {
            path: 'overviewEvaluationApprover/peerfeedback',
            children: [
                {
                    path: 'default',
                    element: <PeerFeedbackApprover />
                }
            ]
        },
        {
            path: '/evaluationoverview/peerfeedback',
            element: <PeerFeedbackUser />
        },
        {
            path: 'evaluationoverviewpeerfeedback',
            children: [
                {
                    path: 'default',
                    element: <PeerFeedbackUser />
                }
            ]
        },
        {
            path: 'evaluationoverview/perfomanceevaluationinstrunction/perfomanceform',
            element: <PerfomanceEvaluationForm />
        },
        {
            path: 'evaluationoverview/perfomanceevaluationinstrunction/perfomanceform',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceEvaluationForm />
                }
            ]
        },
        {
            path: '/overviewEvaluationApprover/perfomanceevaluationinstrunction/perfomanceform',
            element: <PerfomanceEvaluationFormApprover />
        },
        {
            path: 'overviewEvaluatiionApprover/perfomanceevaluationinstrunction/perfomanceform',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceEvaluationFormApprover />
                }
            ]
        },

        {
            path: '/timesheet',
            element: <Timesheet />
        },
        {
            path: 'timesheet',
            children: [
                {
                    path: 'default',
                    element: <Timesheet />
                }
            ]
        },



        {
            path: '/overviewEvaluationApprover',
            element: <OverviewevaluApprover />
        },
        {
            path: 'overviewEvaluatiionApprover',
            children: [
                {
                    path: 'default',
                    element: <OverviewevaluApprover />
                }
            ]
        },


        {
            path: '/overviewEvaluationApprover/perfomanceevaluation',
            element: <PerfomanceEvaluationApprover />
        },
        {
            path: 'overviewEvaluationApprover/perfomanceevaluation',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceEvaluationApprover />
                }
            ]
        },

        {
            path: '/profilesetting',
            element: <ProfileSetting />
        },
        {
            path: '/userlistcard',
            element: <UserListCard />
        },
        {
            path: 'userlistcard',
            children: [
                {
                    path: 'default',
                    element: <UserListCard />
                }
            ]
        },
        {
            path: `/userlistcard/userweeksheet/:WeekKey/:userKey`,
            element: <UserWeeksheet />
        },
        {
            path: 'userlistcard/userweeksheet',
            children: [
                {
                    path: 'default',
                    element: <UserWeeksheet />
                }
            ]
        },
        {
            path: 'profilesetting',
            children: [
                {
                    path: 'default',
                    element: <ProfileSetting />
                }
            ]
        },

        {
            path: '/consultantreports',
            element: <ConsultantUserReport />
        },
        {
            path: 'consultantreports',
            children: [
                {
                    path: 'default',
                    element: <ConsultantUserReport />
                }
            ]
        },
        {
            path: '/trainingreport',
            element: <TraningReport />
        },
        {
            path: 'trainingreport',
            children: [
                {
                    path: 'default',
                    element: <TraningReport />
                }
            ]
        },

        {
            path: '/assettrackinguser',
            element: <AssetTrackinguser />
        },
        {
            path: 'assettrackinguser',
            children: [
                {
                    path: 'default',
                    element: <AssetTrackinguser />
                }
            ]
        },

        {
            path: '/talentacquision',
            element: <TalentAcquision />
        },
        {
            path: 'talentacquision',
            children: [
                {
                    path: 'default',
                    element: <TalentAcquision />
                }
            ]
        },
        {

            path: '/traininguserdashboard',
            element: <TrainingUserDashboard />
        },
        {
            path: 'traininguserdashboard',
            children: [
                {
                    path: 'default',
                    element: <TrainingUserDashboard />
                }
            ]
        },
        {

            path: '/TrainingUserDocument',
            element: <TrainingUserDocument />
        },
        {
            path: 'TrainingUserDocument',
            children: [
                {
                    path: 'default',
                    element: <TrainingUserDocument />
                }
            ]
        },


        {
            path: '/talentacquisiondashboard/manageprofile/talentprofileview',
            element: <TalentProfileView />
        },
        {
            path: 'talentprofileview',
            children: [
                {
                    path: 'default',
                    element: <TalentProfileView />
                }
            ]
        },
        {
            path: '/supplierinvoice',
            element: <SupplierInvoice />
        },
        {
            path: 'supplierinvoice',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoice />
                }
            ]
        },

        {
            path: '/supplierinvoiceadmin',
            element: <SupplierInvoiceAdmin />
        },
        {
            path: 'supplierinvoiceadmin',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoiceAdmin />
                }
            ]
        },

        // utils routing
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
    ]
};

export default UserRoutes;
