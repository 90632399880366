import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery, Card } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import Logogj from 'assets/logo/GJ CloudWhite.jpg'
import MainCard from 'ui-component/cards/MainCard';
// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    {/* <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}> */}
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <AuthCardWrapper>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item sx={{ mb: 0 }}>
                                    <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">

                                        <img src={Logogj} alt='logo' height={80} width={70} />
                                    </a>
                                </Grid>

                                <Grid item xs={12}>
                                    <AuthLogin />
                                </Grid>
                                {/* <Grid item xs={12}>
                                        <Divider />
                                    </Grid> */}
                                <Grid item xs={12}>
                                    <Grid item container direction="column" alignItems="center" xs={12}>
                                        <Typography
                                            component={Link}
                                            to="/singup"
                                            variant="subtitle1"
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {/* Don&apos;t have an account? */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>

                    </Grid>
                    {/* </Grid> */}
                </Grid>

                <Grid item xs={12} >
                    <Grid item >
                        <MainCard>
                            {/* <Typography variant="subtitle2" >
                                            information
                                        </Typography> */}
                            <Typography
                                variant="subtitle1"
                                sx={{ textDecoration: 'none', color: '#673ab7', }}
                            >
                                We use cookies to enhance your browsing experience and provide personalized content.
                                By continuing to use this site, you consent to our use of cookies.
                                You can manage your cookie preferences in your browser settings.
                            </Typography>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default Login;
