// import React, { useState } from 'react';
// import {
//   DesktopOutlined,
//   FileOutlined,
//   PieChartOutlined,
//   TeamOutlined,
//   UserOutlined,
// } from '@ant-design/icons';
// import { Breadcrumb, Layout, Menu, theme } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';

// import { Box } from '@mui/material';
// const { Header, Content, Footer, Sider } = Layout;
// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }
// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];
// const App = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   return (
//     <Layout
//       style={{
//         minHeight: '100vh',
//       }}
//     >
//       <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
//         <div className="demo-logo-vertical" />
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Layout>
//         <Header
//           style={{
//             padding: 0,
//             background: '#363c42',
//             display: 'flex',
//             alignItems: 'center',
//             height: 64, // Adjusted height to match the logo container
//           }}
//         >
//           {/* Logo Section */}
//           <div
//             style={{
//               width: '120px  ',
//               height: '100%',
//               // margin: '0 24px',
//               // background: 'white',
//               display: 'flex',
//               paddingLeft:10,
//               paddingTop:24,
//               alignItems: 'center',
//             }}
//           >
//             {/* Insert your logo image or component here */}
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt='logo' height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//         </Header>
//         <Content
//           style={{
//             margin: '16px 16px',
//           }}
//         >

//           <div
//             style={{
//               padding: 24,
//               minHeight: 360,
//               background: colorBgContainer,
//               borderRadius: borderRadiusLG,
//             }}
//           >
//             <Box sx={{ flexGrow: 1, paddingTop: '20px' }}> 
//               <Outlet />
//             </Box>
//           </div>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };
// export default App;

// import React, { useState } from 'react';
// import { Layout, Menu, theme } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Box } from '@mui/material';

// const { Header, Sider } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Header style={{ background: '#363c42', padding: '0 24px', color: '#fff' }}>
//         {/* Insert your header content here */}
//         <h1 style={{ margin: 0 }}>Your Header</h1>
//       </Header>
//       <Layout>
//         <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//         </Sider>
//         <Layout style={{ marginTop: 10, marginLeft: 10 }}>
//           {/* <Header style={{ background: '#f0f2f5', padding: '10px 24px', fontWeight: 'bold' }}>Content Header</Header> */}
//           <Outlet />
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;


// import React, { useState } from 'react';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Layout, Menu, theme } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { Box } from '@mui/material';

// // const { Sider } = Layout;
// const { Header, Sider, Content } = Layout;
// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//         <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//           <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//             <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//           </a>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Content style={{ margin: '16px 16px' }}>
//         <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//           <Outlet />
//         </Box>
//       </Content>
//     </Layout>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import { Layout, Menu, theme } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Box } from '@mui/material';

// const { Header, Sider } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Header style={{ background: '#363c42', padding: '0 24px', color: '#fff', zIndex: 1 }}>
//         {/* Insert your header content here */}
//         <h1 style={{ margin: 0 }}>Your Header</h1>
//       </Header>
//       <Layout style={{ display: 'flex' }}>
//         <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ minHeight: '100vh', zIndex: 0 }}>
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//         </Sider>
//         <Layout style={{ marginTop: 10, marginLeft: 10, flex: 1 }}>
//           {/* <Header style={{ background: '#f0f2f5', padding: '10px 24px', fontWeight: 'bold' }}>Content Header</Header> */}
//           <Outlet />
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

// import React, { useState } from 'react';
// import { Layout, Menu, theme } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Box } from '@mui/material';

// const { Header, Sider, Content } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ height: '100vh' }}>
//         <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//           <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//             <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//           </a>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Layout>
//       {/* #001529 */}
//         <Header style={{ background: '#062d52', padding: '0 24px', color: '#fff' }}>
//           {/* Insert your header content here */}
//           <h1 style={{ margin: 0 }}>Your Header</h1>
//         </Header>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Outlet />
//           </Box>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

// import React, { useState } from 'react';
// import { Layout, Menu, theme, Button } from 'antd'; // Import Button component from antd
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Box } from '@mui/material';

// const { Header, Sider, Content } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ height: '100vh' }}>
//         <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//           <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//             <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//           </a>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Layout>
//         <Header style={{ background: '#062d52', padding: '0 24px', color: '#fff', display: 'flex', justifyContent: 'space-between' }}>
//           <h1 style={{ margin: 0 }}>Your Header</h1>
//           {/* Add the profile button here */}
//           <Button type="primary" shape="circle" icon={<UserOutlined />} />
//         </Header>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Outlet />
//           </Box>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;


// import React, { useState } from 'react';
// import { Layout, Menu, theme, Button } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Box } from '@mui/material';
// import { Avatar } from 'antd'; // Import Avatar component from antd
// // import ProfileImage from 'path/to/your/profile/image'; // Import your profile image

// const { Header, Sider, Content } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ height: '100vh' }}>
//         <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//           <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//             <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//           </a>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Layout>
//         <Header style={{ background: '#062d52', padding: '0 24px', color: '#fff', display: 'flex', justifyContent: 'space-between' }}>
//           <h1 style={{ margin: 0 }}>Your Header</h1>
//           {/* Add the profile button here */}
//           <Avatar src={Logo} size="large" />
//         </Header>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Outlet />
//           </Box>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

// import React, { useState } from 'react';
// import { Layout, Menu, theme, Avatar } from 'antd';
// import { Outlet } from 'react-router-dom';
// import Logo from 'assets/logo/logo.png';
// import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
// import { Box } from '@mui/material';

// const { Header, Sider, Content } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   getItem('Option 1', '1', <PieChartOutlined />),
//   getItem('Option 2', '2', <DesktopOutlined />),
//   getItem('User', 'sub1', <UserOutlined />, [
//     getItem('Tom', '3'),
//     getItem('Bill', '4'),
//     getItem('Alex', '5'),
//   ]),
//   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//   getItem('Files', '9', <FileOutlined />),
// ];

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         style={{ height: '100vh' }}
//       >
//         <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//           <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//             <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//           </a>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Layout>
//         <Header style={{ background: '#062d52', padding: '0 24px', color: '#fff', display: 'flex', justifyContent: 'space-between' }}>
//           <h1 style={{ margin: 0 }}>Your Header</h1>
//           <Avatar src={Logo} size="large" />
//         </Header>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Outlet />
//           </Box>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

// src/App.js


// import React, { useState } from 'react';
// import { Layout, Menu, theme } from 'antd';
// import { Outlet, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';
// import items from './MenuItems';  

// const { Sider, Content } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave   = () => {
//     setCollapsed(true);
//   };

//   const renderMenuItems = (menuItems) => {
//     return menuItems.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
//             {renderMenuItems(item.children)}
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon}>
//           <Link to={item.key}>{item.label}</Link>
//         </Menu.Item>
//       );
//     });
//   };

//   return (
//       <Layout style={{ minHeight: '100vh' }}>
//         <Sider
//           collapsible
//           collapsed={collapsed}
//           onCollapse={(value) => setCollapsed(value)}
//           onMouseEnter={handleMouseEnter}
//           onMouseLeave={handleMouseLeave}
//         >
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
//             {renderMenuItems(items)}
//           </Menu>
//         </Sider>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Outlet/>
//             <Routes>
//               <Route path="1" element={<div>Option 1 Content</div>} />
//               <Route path="2" element={<div>Option 2 Content</div>} />
//               <Route path="3" element={<div>Tom's Content</div>} />
//               <Route path="4" element={<div>Bill's Content</div>} />
//               <Route path="5" element={<div>Alex's Content</div>} />
//               <Route path="6" element={<div>Team 1 Content</div>} />
//               <Route path="8" element={<div>Team 2 Content</div>} />
//               <Route path="9" element={<div>Files Content</div>} />
//               <Route path="/" element={<div>Default Content</div>} />
//             </Routes>
//           </Box>
//         </Content>
//       </Layout>
//   );
// };

// export default App;

// src/App.js
// import React, { useState } from 'react';
// import { Layout, Menu, theme } from 'antd';
// import { Link, Routes, Route } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';
// import menuItems from './MenuItems'; // Import the single menu configuration

// const { Sider, Content } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   const userRole =JSON.parse(localStorage.getItem('userRole')); 

//   const filterMenuItems = (items, role) => {
//     return items
//       .filter(item => item.roles.includes(role))
//       .map(item => ({
//         ...item,
//         children: item.children ? filterMenuItems(item.children, role) : null,
//       }));
//   };

//   const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   const renderMenuItems = (items) => {
//     return items.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
//             {renderMenuItems(item.children)}
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon}>
//           <Link to={item.path}>{item.label}</Link>
//         </Menu.Item>
//       );
//     });
//   };

//   const renderRoutes = (items) => {
//     return items.flatMap((item) => {
//       if (item.children) {
//         return renderRoutes(item.children);
//       }
//       return <Route key={item.key} path={item.path} element={<item.component />} />;
//     });
//   };

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//           <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//             <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//           </a>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
//           {renderMenuItems(filteredMenuItems)}
//         </Menu>
//       </Sider>
//       <Content style={{ margin: '16px 16px' }}>
//         <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//           <Routes>
//             {renderRoutes(filteredMenuItems)}
//             <Route path="/" element={<div>Default Content</div>} />
//           </Routes>
//         </Box>
//       </Content>
//     </Layout>
//   );
// };

// export default App;


// import React, { useState } from 'react';
// import { Layout, Menu, theme } from 'antd';
// import { Link, Routes, Route } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';
// import menuItems from './MenuItems'; // Import the single menu configuration
// import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

// const { Sider, Content } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   const userRole = JSON.parse(localStorage.getItem('userRole')); 

//   const filterMenuItems = (items, role) => {
//     return items
//       .filter(item => item.roles.includes(role))
//       .map(item => ({
//         ...item,
//         children: item.children ? filterMenuItems(item.children, role) : null,
//       }));
//   };

//   const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   const renderMenuItems = (items) => {
//     return items.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
//             {renderMenuItems(item.children)}
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon}>
//           <Link to={item.path}>{item.label}</Link>
//         </Menu.Item>
//       );
//     });
//   };

//   const handleLogout = () => {
//     // Implement your logout logic here
//     console.log('Logout clicked');
//   };

//   const renderRoutes = (items) => {
//     return items.flatMap((item) => {
//       if (item.children) {
//         return renderRoutes(item.children);
//       }
//       return <Route key={item.key} path={item.path} element={<item.component />} />;
//     });
//   };

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
//       >
//         <div>
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" style={{ flexGrow: 1 }}>
//             {renderMenuItems(filteredMenuItems)}
//           </Menu>
//         </div>
//         <Menu theme="dark" mode="inline">
//           <Menu.SubMenu key="profile" icon={<UserOutlined />} title="Profile">
//             <Menu.Item key="profile-setting" icon={<SettingOutlined />}>
//               <Link to="/profile-setting">Profile Setting</Link>
//             </Menu.Item>
//             <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
//               Logout
//             </Menu.Item>
//           </Menu.SubMenu>
//         </Menu>
//       </Sider>
//       <Content style={{ margin: '16px 16px' }}>
//         <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//           <Routes>
//             {renderRoutes(filteredMenuItems)}
//             <Route path="/" element={<div>Default Content</div>} />
//           </Routes>
//         </Box>
//       </Content>
//     </Layout>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import { Layout, Menu, Tooltip, theme } from 'antd';
// import { Link, Routes, Route, Navigate, useNavigate,useLocation } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';
// import menuItems from './MenuItems'; // Import the single menu configuration
// import { UserOutlined, SettingOutlined, LogoutOutlined ,SmileTwoTone} from '@ant-design/icons';

// const { Sider, Content } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const navigate = useNavigate()
//   const location = useLocation(); 
//   const pathname = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;
//   console.log('Current URL path:', pathname);

//   const {
//     token: { colorBgContainer, borderRadiusLG,siderBg },
//   } = theme.useToken();
//   const userRole = JSON.parse(localStorage.getItem('userRole'));

//   const filterMenuItems = (items, role) => {
//     return items
//       .filter(item => item.roles.includes(role))
//       .map(item => ({
//         ...item,
//         children: item.children ? filterMenuItems(item.children, role) : null,
//       }));
//   };

//   const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };


//   const renderRoutes = (items) => {
//     console.log(location)
//     return items.flatMap((item) => {
//       if (item.children) {
//         return renderRoutes(item.children);
//       }
//       return <Route key={item.key} path={item.path} element={<item.component />} />;
//     });
//   };

//   const renderMenuItems = (items) => {
//     return items.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label} tyle={{color:'',background:''}}style={{ transition: 'all 0.3s ease' }}
// >
//             <Tooltip title={item.label}>
//             {renderMenuItems(item.children)}
//             </Tooltip>
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon} style={{color:'',background:'',transition: 'all 0.3s ease'}}
// >
//             <Tooltip title={item.label}>
//           <Link to={item.path}>{item.label}</Link>
//           </Tooltip>
//         </Menu.Item>
//       );
//     });
//   };

//   const handleLogout = () => {
//     navigate('/login')
//     localStorage.clear()
//   };


//   return (
//     <Layout style={{ minHeight: '100vh' }} >
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         theme='dark'
//         bodyBg='#1b3665'
//         itemSelectedBg='#22adf1'
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//           background: '',
//           // transition: 'width 0.1s ease',
//           // width: collapsed ? 80 : 200, // Adjust width based on collapsed state
//         }}      >
//         <div>
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu itemSelectedBg='#22adf1' theme="dark" defaultSelectedKeys={[pathname]} mode="inline" style={{ flexGrow: 1 }}>
//             {renderMenuItems(filteredMenuItems)}
//           </Menu>
//         </div>
//         <Menu theme="dark" mode="inline">
//           <Menu.SubMenu key="profile" icon={<SmileTwoTone />} title="Profile">
//             <Menu.Item key="profilesetting" icon={<SettingOutlined />}>
//               <Link to="/profilesetting">Profile Setting</Link>
//             </Menu.Item>
//             <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
//               Logout
//             </Menu.Item>
//           </Menu.SubMenu>
//         </Menu>
//       </Sider>
//       <Content style={{ margin: '16px 16px' }}>
//         <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//           <Routes>
//             {renderRoutes(filteredMenuItems)}
//             <Route path="/" element={<div>Default Content</div>} />
//           </Routes>
//         </Box>
//       </Content>
//     </Layout>

//   );
// };

// export default App;


// import React, { useState } from 'react';
// import { Layout, Menu, Tooltip, Breadcrumb, theme } from 'antd';
// import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';
// import menuItems from './MenuItems'; // Import the single menu configuration
// import { UserOutlined, SettingOutlined, LogoutOutlined, SmileTwoTone,HomeOutlined } from '@ant-design/icons';
// import BreadcrumbComponent from './Breadcrumbs';
// const { Sider, Content, Header } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const navigate = useNavigate();
//   const location = useLocation(); 
//   const [selectedKeys, setSelectedKeys] = useState([]);

//   const pathname = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;
//   const pathSnippets = pathname.split('/').filter(i => i);
//   console.log('Current URL path:', pathname);

//   const {
//     token: { colorBgContainer, borderRadiusLG, siderBg },
//   } = theme.useToken();
//   const userRole = JSON.parse(localStorage.getItem('userRole'));

//   const filterMenuItems = (items, role) => {
//     return items
//       .filter(item => item.roles.includes(role))
//       .map(item => ({
//         ...item,
//         children: item.children ? filterMenuItems(item.children, role) : null,
//       }));
//   };

//   const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   const handleClick = (e) => {
//     setSelectedKeys([e.key]);
//   };


//   const renderRoutes = (items) => {
//     console.log(location);
//     return items.flatMap((item) => {
//       if (item.children) {
//         return renderRoutes(item.children);
//       }
//       return <Route key={item.key} path={item.path} element={<item.component />} />;
//     });
//   };

//   const renderMenuItems = (items) => {
//     return items.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label} style={{ transition: 'all 0.3s ease' }}>
//             <Tooltip title={item.label}>
//               {renderMenuItems(item.children)}
//             </Tooltip>
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon} style={{ color: '', background: '', transition: 'all 0.3s ease' }}>
//           <Tooltip title={item.label}>
//             <Link to={item.path} onClick={handleClick}>{item.label}</Link>
//           </Tooltip>
//         </Menu.Item>
//       );
//     });
//   };

//   const handleLogout = () => {
//     navigate('/login');
//     localStorage.clear();
//   };


//   // const breadcrumbItems = [
//   //   <Breadcrumb.Item key="home">
//   //     <Link to="/dashboard">
//   //       <HomeOutlined /> Home
//   //     </Link>
//   //   </Breadcrumb.Item>,
//   //   ...pathSnippets.map((snippet, index) => {
//   //     const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
//   //     return (
//   //       <Breadcrumb.Item key={url}>
//   //         <Link to={url}>{snippet}</Link>
//   //       </Breadcrumb.Item>
//   //     );
//   //   })
//   // ];
//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         theme="dark"
//         bodyBg="#1b3665"
//         itemSelectedBg="#22adf1"
//         selectedKeys={selectedKeys}
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//           background: '',
//         }}
//       >
//         <div>
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu itemSelectedBg="#22adf1" theme="dark" defaultSelectedKeys={[pathname]} mode="inline" style={{ flexGrow: 1 }}>
//             {renderMenuItems(filteredMenuItems)}
//           </Menu>
//         </div>
//         <Menu theme="dark" mode="inline">
//           <Menu.SubMenu key="profile" icon={<SmileTwoTone />} title="Profile">
//             <Menu.Item key="profilesetting" icon={<SettingOutlined />}>
//               <Link to="/profilesetting">Profile Setting</Link>
//             </Menu.Item>
//             <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
//               Logout
//             </Menu.Item>
//           </Menu.SubMenu>
//         </Menu>
//       </Sider>
//       <Layout>
//         <Header style={{ background: colorBgContainer, padding: 0 }}>
//           <Breadcrumb style={{ margin: '16px' }}>
//             <BreadcrumbComponent/>
//             {/* {breadcrumbItems} */}
//           </Breadcrumb>
//         </Header>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Routes>
//               {renderRoutes(filteredMenuItems)}
//               <Route path="/" element={<div>Default Content</div>} />
//             </Routes>
//           </Box>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

import React, { useState } from 'react';
import { Layout, Menu, Tooltip, Breadcrumb, theme } from 'antd';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Logo from 'assets/logo/GJ CloudWhite.jpg';
import { IconUserCircle ,IconSettingsStar,IconLogout} from '@tabler/icons-react';
import menuItems from './MenuItems';
import { UserOutlined, SettingOutlined, LogoutOutlined, SmileTwoTone, HomeOutlined } from '@ant-design/icons';
import BreadcrumbComponent from './Breadcrumbs';

import Loadable from 'ui-component/Loadable';
import { Alert, Space } from 'antd';

import { lazy } from 'react';
import routes from 'routes/Route.config';
import AlertW9Doc from 'views/modules/SupplierModules/DocumentAlert/AlertW9Doc';
import AlertCoiDoc from 'views/modules/SupplierModules/DocumentAlert/AlertCoiDoc';

const { Sider, Content, Header } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);

  const pathname = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;
  const pathSplit = pathname.split('/');
console.log(pathSplit);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const userRole = JSON.parse(localStorage.getItem('userRole'));

  const filterMenuItems = (items, role) => {
    return items
      .filter(item => item.roles.includes(role))
      .map(item => ({
        ...item,
        children: item.children ? filterMenuItems(item.children, role) : null,
      }));
  };

  const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
  };

  const handleClick = (e) => {
    setSelectedKeys([e.key]);
  };

  const renderRoutes = (items) => {
    return items.flatMap((item) => {
      if (item.children) {
        return renderRoutes(item.children);
      }
      return <Route key={item.key} path={item.path} element={<item.component />} />;
    });
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu key={item.key} icon={item.icon} title={item.label} style={{ transition: 'all 0.3s ease' }}>
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} icon={item.icon} style={{ transition: 'all 0.3s ease' }}>
          <Link to={item.path} onClick={handleClick}>{item.label}</Link>
        </Menu.Item>
      );
    });
  };

  const handleLogout = () => {
    navigate('/login');
    localStorage.clear();
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        theme="dark"
        bodyBg="#1b3665"
        // itemSelectedBg="#22adf1"
        selectedKeys={selectedKeys}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: '',
        }}
      >
        <div>
          <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
            <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
              <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
            </a>
          </div>
          <Menu itemSelectedBg="#22adf1" theme="dark" defaultSelectedKeys={pathSplit} mode="inline" style={{ flexGrow: 1 }}>
            {renderMenuItems(filteredMenuItems)}
          </Menu>
        </div>
        {userRole.name == 'Supplier' ?
          <Menu theme="dark" mode="inline">
            <Menu.SubMenu key="profile" icon={<IconUserCircle />} title="Profile">
              <Menu.Item key="profilesettingsupplier" icon={<IconSettingsStar />}> 
                <Link to="/profilesettingsupplier">Profile Setting</Link>
              </Menu.Item>
              <Menu.Item key="logout" icon={<IconLogout />} onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu> :
          <Menu theme="dark" mode="inline">
            <Menu.SubMenu key="profile" icon={<IconUserCircle />} title="Profile">
              <Menu.Item key="profilesetting" icon={<IconSettingsStar />}>
                <Link to="/profilesetting">Profile Setting</Link>
              </Menu.Item>
              <Menu.Item key="logout" icon={<IconLogout />} onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>}
      </Sider>
      <Layout>
        <Header style={{ background: colorBgContainer, padding: 0 }}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <Breadcrumb style={{ margin: '16px' }}>
            <BreadcrumbComponent />
          </Breadcrumb>
        {userRole.id==10&&
          <>
           <AlertCoiDoc/>
          <AlertW9Doc/>
          </>} 
          {/* <div  style={{ margin: '35px',display:'flex' ,gap:10,marginTop:16,maxHeight:35}}>
        <Alert message="Please update latest COI Document in profile section" type="error" showIcon closable />
        <Alert message="Please update latest W9 Document in profile section" type="error" showIcon closable />
        </div> */}
        </div>

         
        </Header>
        <Content style={{ margin: '16px 16px' }}>
          {/* <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}> */}
            <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            </Routes>
          {/* </Box> */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;

// =================================================new updated

// import React, { useState ,useEffect} from 'react';
// import { Layout, Menu, Tooltip, Breadcrumb, theme } from 'antd';
// import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';
// import routes from 'routes/Route.config';
// import menuItems from './MenuItems';
// import { UserOutlined, SettingOutlined, LogoutOutlined, SmileTwoTone, HomeOutlined } from '@ant-design/icons';
// import BreadcrumbComponent from './Breadcrumbs';
// const ProfileSettingSupplier = Loadable(lazy(() => import('views/screens/profile/profilesettingSupplier')))
// const ProfileSetting = Loadable(lazy(() => import('views/screens/profile/profilesetting')))
// const ManageProfile = Loadable(lazy(() => import('views/modules/AdminModules/TalentAcquision/ManageProfile')))
// const ManagePosition = Loadable(lazy(() => import('views/modules/AdminModules/TalentAcquision/ManagePosition')))

// import Loadable from 'ui-component/Loadable';
// import { lazy } from 'react';

// const { Sider, Content, Header } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [selectedKeys, setSelectedKeys] = useState([]);

//   const pathname = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;

//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   const userRole = JSON.parse(localStorage.getItem('userRole'));

//   const filterMenuItems = (items, role) => {
//     return items
//       .filter(item => item.roles.includes(role))
//       .map(item => ({
//         ...item,
//         children: item.children ? filterMenuItems(item.children, role) : null,
//       }));
//   };

//   const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   const handleClick = (e) => {
//     setSelectedKeys([e.key]);
//   };

//   const renderRoutes = (items) => {
//     return items.flatMap((item) => {
//       if (item.children) {
//         return renderRoutes(item.children);
//       }
//       return <Route key={item.key} path={item.path} element={<item.component />} />;
//     });
//   };

//   const renderMenuItems = (items) => {
//     return items.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label} style={{ transition: 'all 0.3s ease' }}>
//             {renderMenuItems(item.children)}
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon} style={{ transition: 'all 0.3s ease' }}>
//           <Link to={item.path} onClick={handleClick}>{item.label}</Link>
//         </Menu.Item>
//       );
//     });
//   };

//   const handleLogout = () => {
//     navigate('/login');
//     localStorage.clear();
//   };

//   useEffect(() => {
//     console.log(routes)
//   }, [])
  
//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         theme="dark"
//         bodyBg="#1b3665"
//         // itemSelectedBg="#22adf1"
//         selectedKeys={selectedKeys}
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//           background: '',
//         }}
//       >
//         <div>
//           <div style={{ textAlign: 'center', padding: '16px 0', background: '#001529' }}>
//             <a href="https://gj-consultinggroup.com/" target="_blank" rel="noopener noreferrer">
//               <img src={Logo} alt="logo" height={65} width={70} style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//             </a>
//           </div>
//           <Menu itemSelectedBg="#22adf1" theme="dark" defaultSelectedKeys={[pathname]} mode="inline" style={{ flexGrow: 1 }}>
//             {renderMenuItems(filteredMenuItems)}
//           </Menu>
//         </div>
//         {userRole.name == 'Supplier' ?
//           <Menu theme="dark" mode="inline">
//             <Menu.SubMenu key="profile" icon={<SmileTwoTone />} title="Profile">
//               <Menu.Item key="profilesettingsupplier" icon={<SettingOutlined />}> Profile Setting

//                 <Link to="/profilesettingsupplier">Profile Setting</Link>

//               </Menu.Item>
//               <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
//                 Logout
//               </Menu.Item>
//             </Menu.SubMenu>
//           </Menu> :
//           <Menu theme="dark" mode="inline">
//             <Menu.SubMenu key="profile" icon={<SmileTwoTone />} title="Profile">
//               <Menu.Item key="profilesetting" icon={<SettingOutlined />}>Profile Setting
//                 <Link to="/profilesetting">Profile Setting</Link>
//               </Menu.Item>
//               <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
//                 Logout
//               </Menu.Item>
//             </Menu.SubMenu>
//           </Menu>}
//       </Sider>
//       <Layout>
//         <Header style={{ background: colorBgContainer, padding: 0 }}>
//           <Breadcrumb style={{ margin: '16px' }}>
//             <BreadcrumbComponent />
//           </Breadcrumb>
//         </Header>
//         <Content style={{ margin: '16px 16px' }}>
//           <Box sx={{ padding: 3, background: colorBgContainer, borderRadius: borderRadiusLG }}>
//             <Routes>
//               {/* {renderRoutes(filteredMenuItems)} */}
//               <Route path="/" element={<div>Default Content</div>} />
//               {routes.map((x,y)=>{
//                 console.log(x,y),
//                 <Route key={y} path={x.path} element={x.element} />
//               })}
//               {/* <Route path="/profilesetting" element={<ProfileSetting />} />
//               <Route path="/manageprofile" element={<ManageProfile />} />
//               <Route path="/manageposition" element={<ManagePosition />} />
//               <Route path="/profilesettingsupplier" element={<ProfileSettingSupplier />} /> */}
//             </Routes>
//           </Box>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;


// src/App.js





// import React, { useState } from 'react';
// import { Layout, Menu, Tooltip, Breadcrumb, theme } from 'antd';
// import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// import { Box } from '@mui/material';
// import Logo from 'assets/logo/logo.png';

// import menuItems from './MenuItems'; // Import the single menu configuration
// import { UserOutlined, SettingOutlined, LogoutOutlined, SmileTwoTone, HomeOutlined } from '@ant-design/icons';
// import BreadcrumbComponent from './Breadcrumbs';

// import routes from 'routes/Route.config'; 

// const { Sider, Content, Header } = Layout;

// const App = () => {
//   const [collapsed, setCollapsed] = useState(true);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [selectedKeys, setSelectedKeys] = useState([]);

//   const pathname = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;

//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   const userRole = JSON.parse(localStorage.getItem('userRole'));

//   const filterMenuItems = (items, role) => {
//     return items
//       .filter(item => item.roles.includes(role))
//       .map(item => ({
//         ...item,
//         children: item.children ? filterMenuItems(item.children, role) : null,
//       }));
//   };

//   const filteredMenuItems = filterMenuItems(menuItems, userRole?.name);

//   const handleMouseEnter = () => {
//     setCollapsed(false);
//   };

//   const handleMouseLeave = () => {
//     setCollapsed(true);
//   };

//   const handleClick = (e) => {
//     setSelectedKeys([e.key]);
//   };

//   const renderMenuItems = (items) => {
//     return items.map((item) => {
//       if (item.children) {
//         return (
//           <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
//             {renderMenuItems(item.children)}
//           </Menu.SubMenu>
//         );
//       }
//       return (
//         <Menu.Item key={item.key} icon={item.icon}>
//           <Link to={item.path}>{item.label}</Link>
//         </Menu.Item>
//       );
//     });
//   };

//   return (
//     <Box sx={{ display: 'flex', height: '100vh' }}>
//       <Sider
//         width={200}
//         style={{ background: colorBgContainer }}
//         collapsible
//         collapsed={collapsed}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//           <img src={Logo} alt="Logo" style={{ width: collapsed ? '50%' : '80%' }} />
//         </div>
//         <Menu
//           mode="inline"
//           selectedKeys={selectedKeys}
//           style={{ height: '100%', borderRight: 0 }}
//           onClick={handleClick}
//         >
//           {renderMenuItems(filteredMenuItems)}
//         </Menu>
//       </Sider>
//       <Layout>
//         <Header style={{ background: colorBgContainer, padding: 0 }}>
//           <BreadcrumbComponent pathname={pathname} menuItems={menuItems} />
//         </Header>
//         <Content style={{ padding: '0 24px', minHeight: 280 }}>
//           <Routes>
//             {routes.map((route, index) => (
//               <Route key={index} path={route.path} element={route.element} />
//             ))}
//           </Routes>
//         </Content>
//       </Layout>
//     </Box>
//   );
// };

// export default App;

